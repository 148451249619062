import React from "react";
import DictionaryMeaning from "./DictionaryMeaning";


const App = () => {
  

  return (
    <div>
           

      {/* Dynamic Dictionary Lookup */}
      <DictionaryMeaning />

     
     
    </div>
  );
};

export default App;
