import React, { useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeUp } from "@fortawesome/free-solid-svg-icons";

const DictionaryMeaning = () => {
  const [word, setWord] = useState("");
  const [meaning, setMeaning] = useState("");
  const [error, setError] = useState("");
  const [currentWordIndex, setCurrentWordIndex] = useState(null);

  // Function to fetch the meaning of the word
  const fetchMeaning = async () => {
    try {
      const response = await axios.get(
        `https://api.dictionaryapi.dev/api/v2/entries/en/${word}`
      );
      const meaningData = response.data[0].meanings[0].definitions[0].definition;
      setMeaning(meaningData);
      setError("");
    } catch (err) {
      setError("Word not found. Please try another.");
      setMeaning("");
    }
  };

  // Function to read aloud the meaning, word-by-word, and highlight the current word
  const readAloud = () => {
    const words = meaning.split(" ");
    let index = 0;

    const utterWord = () => {
      if (index < words.length) {
        const utterance = new SpeechSynthesisUtterance(words[index]);
        setCurrentWordIndex(index); // Highlight current word

        utterance.onend = () => {
          index += 1;
          utterWord(); // Move to the next word
        };

        speechSynthesis.speak(utterance);
      } else {
        setCurrentWordIndex(null); // Clear highlighting when done
      }
    };

    utterWord(); // Start reading
  };

  return (
    <div  class="center-div">
      <h5>English Dictionary </h5> 
 <input
        type="text"
        placeholder="Enter a word"
        value={word}
        onChange={(e) => setWord(e.target.value)}
      />
      <button onClick={fetchMeaning}>Get Meaning</button>

      {meaning && (
        <div>
          <h4>
            Meaning of "{word}": 
            {/* Speaker Icon to trigger read-aloud */}
            <FontAwesomeIcon
              icon={faVolumeUp}
              onClick={readAloud}
              style={{ cursor: "pointer", marginLeft: "10px", color: "#007bff" }}
              title="Read aloud"
            />
          </h4>
          {/* Display meaning with highlighted current word */}
          <p>
            {meaning.split(" ").map((word, index) => (
              <span
                key={index}
                style={{
                  color: index === currentWordIndex ? "blue" : "black",
                  fontWeight: index === currentWordIndex ? "bold" : "normal",
                }}
              >
                {word}{" "}
              </span>
            ))}
          </p>
        </div>
      )}

      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default DictionaryMeaning;
